.app__navbar {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.25);
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 2;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
   height: 45px;
   width: 155px;
   filter: drop-shadow(4px 4px 4px #575b80af);
    @media screen and (min-width: 2000px) {
      width: 180px;
      height: 40px;
    }
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;

  li {
    margin: 0 1rem;
    cursor: pointer;

    flex-direction: column;

    div {
      width: 5px;
      height: 5px;
      background: transparent;
      border-radius: 50%;

      margin-bottom: 5px;
    }

    a {
      color: var(--gray-color);
      text-decoration: none;
      flex-direction: column;

      text-transform: uppercase;
      font-weight: 500;

      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--secondary-color);
      }
    }

    &:hover {
      div {
        background: var(--secondary-color);
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}
.btn-nav {
  padding: 0.5rem 2rem;
  border-radius: 4px;
  border: none;
  background-color: var(--secondary-color);
  justify-items: center;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
  color: white;
  outline: none;
  filter: drop-shadow(6px 6px 6px #575b80af);
  font-family: var(--font-base);

  transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  cursor: pointer;

  &:hover {
    background-color: #2430af;
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);


  svg {
    width: 70%;
    height: 70%;
    color: var(--white-color);
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;

    padding: 1rem;

    width: 80%;
    height: 100vh;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    background: url('../../assets/bgWhite.png');
    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;

    /* top box shadow */
    box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

    svg {
      width: 35px;
      height: 35px;
      color: var(--secondary-color);
      margin: 0.5rem 1rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      li {
        margin: 1rem;

        a {
          color: var(--gray-color);
          text-decoration: none;
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 500;

          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--secondary-color);
          }
        }
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
    .btn-mobile {
      padding: 0.5rem 2rem;
      border-radius: 4px;
      margin-bottom: 6rem;
      border: none;
      background-color: var(--secondary-color);
      justify-items: center;
      align-items: center;
      justify-content: flex-end;
      font-weight: 500;
      color: white;
      outline: none;
      filter: drop-shadow(6px 6px 6px #575b80af);
      font-family: var(--font-base);
    
      transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
      cursor: pointer;
    
      &:hover {
        background-color: #2430af;
      }
    
    }
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
}

.mobile-list{
  @media screen and (min-width: 900px) {
    display: none;
  }
  
  font-size: 20px;
  backdrop-filter: blur(10px);
  background:#F2F5FA;
  box-shadow: 0 -1px 12px rgba(0, 0, 0, 0.299);
  color: var(--primary-color);
  padding: 0.8rem 0 0.5rem 0;
  list-style-type: none;
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: row wrap;
  flex: 1 100%;
  width:100%;
  font-weight: 500;
  align-self: center;
  bottom: 0;
  left: 0;

}
.mobile-list ul li{
color: var(--primary-color);
}
