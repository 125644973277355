@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}


.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 15s linear infinite;
  width: 180%;
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}

.maylike-products-wrapper{
  margin-top: 30px;
}
.maylike-products-wrapper h2{
  text-align: center;
  margin: 50px;
  color: #324d67;

  font-size: 28px;
}
.maylike-products-container{
  display: flex;
  justify-content: center;
 
  margin-top: 20px;
}


.marquee-text{
  font-size: 29px;
  font-weight: 600;
  margin: 60px 0px;
  color: #f02d34;
}
.marquee {
  position: relative;
  height: 100%;
width: 100%;
  overflow-x: hidden;
}

.track:hover {
  animation-play-state: paused;
}
